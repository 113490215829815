let uid = 0;

const consoleLog = (message) => {
	global.storeReference.dispatch('consoleLog', { log: message });
}
const consoleError = (message) => {
	global.storeReference.dispatch('consoleError', { log: message });
}
const consoleSticky = (message) => {
	global.storeReference.dispatch('consoleSticky', { log: message });
}

const dragDropEvent = (event_normal, event_alpha) => {
	document.addEventListener("dragover", function (event) {
		event.preventDefault();
	});

	// Main drop area
	document.addEventListener('drop', ignoreDrop, false);
	addDragBehavior(document.body, 'dropTarget', 'dropTarget_visible')

	// Normal area
	addDragBehavior(document.getElementById('dropTarget_normal'), 'dropTarget_normal', 'dropTarget_active')
	document.getElementById('dropTarget_normal').addEventListener('drop', (e) => { event_normal(e) }, false);

	// Alpha area
	addDragBehavior(document.getElementById('dropTarget_alpha'), 'dropTarget_alpha', 'dropTarget_active')
	document.getElementById('dropTarget_alpha').addEventListener('drop', (e) => { event_alpha(e) }, false);
}

function ignoreDrop(e) {
	e.stopPropagation();
	e.preventDefault();
	return false;
}

function addDragBehavior(container, target, className) {
	let dragTimer = null;

	container.addEventListener('dragover', dragBehavior, false);

	function dragBehavior() {
		clearTimeout(dragTimer);
		dragTimer = setTimeout(() => {
			document.getElementById(target).classList.remove(className);
		}, 50);

		document.getElementById(target).classList.add(className);
	}
}


const simulateClick = (elem) => {
	// Create our event (with options)
	var evt = new MouseEvent("click", {
		bubbles: true,
		cancelable: true,
		view: window,
	});
	// If cancelled, don't dispatch our event
	var canceled = !elem.dispatchEvent(evt);
};


const getUID = () => {
	uid++;
	return uid;
}

const radians = (angle) => {
	return angle * (Math.PI / 180);
};

const lerp = (min, max, amount) => {
	return min + amount * (max - min);
};

const map = (value, start1, stop1, start2, stop2) => {
	return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1));
}

const random = (min, max) => {
	if (Object.prototype.toString.call(min) === '[object Array]') return min[~~(Math.random() * min.length)];

	if (typeof max !== 'number') {
		max = min || 1;
		min = 0;
	}

	return min + Math.random() * (max - min);
};

const randomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

const randomColor = () => {
	const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
	let hex = '#';
	for (let i = 0; i < 6; i++) {
		const index = Math.floor(Math.random() * hexValues.length)
		hex += hexValues[index];
	}

	return hex;
}

const hexToRgbA = (hex, opacity) => {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
	}
	throw new Error('Bad Hex');
}




export { consoleLog, consoleError, consoleSticky, getUID, radians, lerp, map, random, randomInt, randomColor, hexToRgbA, dragDropEvent, simulateClick }